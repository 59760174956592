import widgetImage1 from "../Images/image1.avif"
import widgetImage2 from "../Images/image2.avif"
import widgetImage3 from "../Images/image3.avif"
import widgetImage4 from "../Images/image4.avif"
import widgetImage5 from "../Images/image5.avif"
import widgetImage6 from "../Images/image6.avif"


const IndustryData = [{
        IndustryImage: widgetImage1,
        IndustryBottomColor: "#341948",
        IndustryPara: "Diploma in Computer Applications",
    },
    {
        IndustryImage: widgetImage2,
        IndustryBottomColor: "#0b3978",
        IndustryPara: "ADCA (Advanced Diploma in Computer Applications)",
    },
    {
        IndustryImage: widgetImage3,
        IndustryBottomColor: "#55710d",
        IndustryPara: "Innovative Accounting Solutions: Tally Software Revolutionizes Financial Management.",
    },
    {
        IndustryImage: widgetImage4,
        IndustryBottomColor: "#0836AE",
        IndustryPara: "The Power of Desktop Publishing (DTP) in Visual Communication.",
    },
    {
        IndustryImage: widgetImage5,
        IndustryBottomColor: "#43485e",
        IndustryPara: "The Art of Efficient Writing for Rapid Communication (Shorthand)",
    },
    {
        IndustryImage: widgetImage6,
        IndustryBottomColor: "#021033",
        IndustryPara: "The Gateway to Efficient Communication in the Digital Age (Typing).",
    },
]

export default IndustryData