import React from 'react'
import dataObj from './GalleryData'

const Gallery = () => {
  return (
    <div className='w-full pb-20'>
    <div className='w-full h-[300px] bg-red flex items-center justify-center'>
        <h1 className='font-bold text-2xl text-white'>Gallery</h1>
    </div>
    <div className='flex flex-wrap w-full items-center justify-center mt-4'>
        {
            dataObj && dataObj.map((item)=>{
                return(
                    <div key={item.id} className=' md:w-[350px] m-1'>
                        <img className='w-full h-[300px]' src={item.imageUrl} alt="" />
                    </div>
                )
            })
        }
    </div>
    </div>
  )
}

export default Gallery