import homeimg1 from "../Image/homeHeroBg1-12.jpg";
import homeimg2 from "../Image/Infrastructure-Automation-Services-1.jpg";
import homeimg3 from "../Image/Software-Defined-Networking-SDN-Solutions.png";
import homeimg4 from "../Image/Virtualization-01.jpg";

const HeaderCart = [
 
  {
    id: 1,
    img: homeimg2,
    name: "Diploma in Computer Applications",
    para: "DCA services encompass a range of offerings tailored to meet the diverse needs of clients in the field of computer applications.These services typically include software development, database management, web development, networking solutions, and IT support."
  },
   {
     id: 2,
     img: homeimg1,
     name: "ADCA (Advanced Diploma in Computer Applications)",
     para: "ADCA(Advanced Diploma in Computer Applications) is an intensive program that delves deep into various aspects of computer technology and its practical applications.It extends beyond the basics of software development, database management, and web development covered in standard DCA courses"

   },
  {
    id: 3,
    img: homeimg3,
    name: "Innovative Accounting Solutions: Tally Software Revolutionizes Financial Management",
    para: "It simplifies complex accounting processes, offering features like invoicing, expense tracking, tax calculations, and financial reporting. With its user-friendly interface and customizable options, Tally enables efficient management of business finances, ensuring compliance with tax regulations and financial standards. It also facilitates seamless integration with banking systems, making banking transactions hassle-free. "
  },
  {
    id: 4,
    img: homeimg4,
    name: "The Power of Desktop Publishing (DTP) in Visual Communication",
    para: "Desktop Publishing(DTP) is a digital toolset that empowers users to create visually appealing documents, such as flyers, brochures, magazines, and newsletters.It combines text, images, and graphical elements in a layout software to produce professional - quality publications "
  },
];

export default HeaderCart;
