import React, { useState } from "react";
import ContactHead from "../Image/freshhh.png";
// import conatctImg from "../Image/conatct.png";
import { HiLocationMarker } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import { BiPhoneCall } from "react-icons/bi";

import "./Contact.css";

const Contact = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    course: "",
  });

  const options = ["ADCA", "DCA", "Excel", "Tally","DTP", "Short Hand"]
  const [myValue, setMyValue] = useState(options[0]);
  const onHandleChange = (e) => {
    const formData = { ...data };
    formData[e.target.name] = e.target.value;
    setData(formData);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
  //  window.Email.send({
  //     Host: "smtp.elasticemail.com",
  //     Username: "cbytecomputer2021@gmail.com",
  //     Password: "2D4D0214A80599128219DC0B3E693493A6A1",
  //     To: 'cbytecomputer2021@gmail.com',
  //     From: "cbytecomputer2021@gmail.com",
  //     Subject: "This is the subject",
  //     Body: "And this is the body"
  //   }).then(
  //     message => alert(message)
  //   );
    // window.Email.send({
    //   Host: "smtp.elasticemail.com",
    //   Username: "cbytecomputer2021@gmail.com",
    //   Password: "2D4D0214A80599128219DC0B3E693493A6A1",
    //   Port: 2525,
    //   To: 'cbytecomputer2021@gmail.com',
    //   From: `cbytecomputer2021@gmail.com`,
    //   Subject: `${data.name}`,
    //      Body: `<div className="bg-[#04789D]">
    //   <p className="font-bold text-base text-[#04789D]">Name : ${data.name}</p> <br>
    //   <p className="font-medium text-base text-[#04789D]">Email : ${data.email}</p> <br>
    //   <p className="font-medium text-base text-[#04789D]">Subject : ${data.phone}</p> <br>
    //   </div> `
    // }).then(
    //        message => {
    //          if (message === "OK") {
    //            console.log("message has been send, thank you for connecting")
    //          } else {
    //            console.log("there is some error")
    //          }
    //        }
    //      );

       window.Email.send({
         Host: "smtp.elasticemail.com",
         Username: "cbytecomputer2021@gmail.com",
         Password: "2D4D0214A80599128219DC0B3E693493A6A1",
         Port: 2525,
         To: "cbytecomputer2021@gmail.com",
         From: "cbytecomputer2021@gmail.com",
         Subject: `${data.name}`,
         Body: `<div className="bg-[#04789D]">
      <p className="font-bold text-base text-[#04789D]">Name : ${data.name}</p> <br>
      <p className="font-medium text-base text-[#04789D]">Email : ${data.email}</p> <br>
      <p className="font-medium text-base text-[#04789D]">Phone : ${data.phone}</p> <br>
      <p className="font-medium text-base text-[#04789D]">Phone : ${myValue}</p> <br>     
      </div>
      `
       }).then(
         message => {
           if (message === "OK") {
             alert("message has been send, thank you for connecting")
           } else {
             alert("there is some error")
           }
         }
       );
  };

  return (
    <div className="pt-5 pb-10 font-roboto">
      <div className="w-full h-full overflow-hidden	 relative flex items-center justify-center ">
        <img
          className="w-full h-full md:mt-[-100px]"
          src={ContactHead}
          alt=""
        />
        <div className="absolute flex items-center justify-center z-10">
          <h1 className="text-center font-bold md:text-4xl text-[#04789D] ">
            Contact Us
          </h1>
        </div>
      </div>

      <div className="flex flex-col md:flex-row  justify-center pt-10 pb-10 pl-5 md:pl-10 pr-5 md:pr-10">
        <div className="w-full flex flex-col pl-5 sm:pl-10  md:pl-0 md:w-3/6">
          {/* <img src={conatctImg} alt="" /> */}
          <h1 className="font-bold text-4xl text-[#04789D]">Get In Touch</h1>
          <div className="flex flex-col">
            <h1 className="font-semibold text-xl pt-2">Reach Us</h1>
            <div className="flex  pt-3">
              <HiLocationMarker size={20} color="#04789D" />
              <p className="font-normal text-base capitalize ml-2 w-[350px]">
                Byte computer classes, Suryapura ghat, Suryapura, Bhagwanpur, Begusarai, Bihar - 851120
              </p>
            </div>
            <div className="flex pt-2">
              <MdEmail size={20} color="#04789D" />
              <a href="mailto:byteclasses@cbyteclass.com
" className="font-normal text-base capitalize ml-2">
                byteclasses@cbyteclass.com

              </a>
            </div>
            <div className="flex pt-2 ">
              <BiPhoneCall size={20} color="#04789D" />
              <a href="tel:8581017502" className="font-normal text-base capitalize ml-2">8581017502</a>
            </div>
            {/* <div className="flex pt-2 ">
              <BiPhoneCall size={20} color="#04789D" />
              <p className="font-normal text-base capitalize ml-2">123456789</p>
            </div> */}
          </div>
        </div>
        <div className="w-full md:w-5/6 flex items-center justify-center shadow-2xl p-5">
          <form className="w-full " onSubmit={handleSubmit}>
            <div class="mb-3">
              <label
                for="name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Name
              </label>
              <input
                type="name"
                id="name"
                name="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name"
                value={data.name}
                onChange={onHandleChange}
                
              />
            </div>
            <div className="mb-3">
              <label
                for="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Your email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@gmail.com"
                value={data.email}
                onChange={onHandleChange}
                
              />
            </div>
            <div className="mb-3">
              <label
                for="password"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Phone
              </label>
              <input
                type="number"
                id="phone"
                name="phone"
                placeholder="phone"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={data.phone}
                onChange={onHandleChange}
                
              />
            </div>
                  <div>
                       <label
                for="password"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Select Your Course
              </label>

              <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" name="" id="" onChange={(e)=>setMyValue(e.target.value)}>
              {
                options.map((item,index)=>{
                  console.log(item)
                  return(
                  
                       <option key={index}>{item}</option>
                    
                  )
                })
              }
                <option value="" defaultValue="disable">select course</option>

              </select>
            </div>

            {/* <div className="mb-3">
              <label
                for="message"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Message
              </label>
              <textarea
                type="message"
                id="message"
                name="message"
                placeholder="message"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={data.message}
                onChange={onHandleChange}
                required
              />
            </div> */}
            <button
              type="submit"
              className="text-black capitalize  bg-[#04789D]  pl-7 pr-7 mt-3 pt-2 pb-2 rounded text-white font-normal text-base"
            >
              send message
            </button>
          </form>
        </div>
      </div>
      <div className="pl-5 md:pl-10 pr-5 md:pr-10">
        {/* <div className="mapouter"> */}
         
          <div><iframe id="map"  title="myFrame" width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Suryapura%20ghat,%20Suryapura,%20Bhagwanpur,%20Begusarai,%20Bihar-851120+(Byte%20Computer%20Classes)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/sport-gps/">byte computer</a></iframe></div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Contact;
