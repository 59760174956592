import React from "react";
import devops from "../Image/devOpsRightColImg-1-removebg-preview.png";
import Tally1 from "../Images/tally1.jpeg"
import DCA1 from "../Images/dca1.png";
// import serviceData from "../Services/ServiceData";
import cartData from "./CartApp";
import Slider from "react-slick";

const AppDevelopment = () => {
  var settings = {
    infinite: true,
    speed: 1000,
    pauseOnHover: false,
    autoplay: true,
    variableWidth: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="font-roboto flex flex-col items-center justify-center pb-20">
      <div className="w-full h-[300px] sm:h-[450px] overflow-hidden">
        <div className="w-full h-full mt-5 ">
          <Slider {...settings}>
            {cartData.map((item) => {
              return (
                <>
                  <div
                    className="w-full h-[300px] sm:h-[450px]  flex  items-center justify-start"
                    style={{
                      backgroundImage: `url(${item.img})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  >
                    {/* <img src={item.img} alt="" /> */}
                    <div className="flex flex-col pl-5 sm:pl-20">
                      <h1 className="font-bold text-4xl text-white">
                        {item.name}
                      </h1>
                      <p className="text-white pt-5 w-2/3">{item.para}</p>
                    </div>
                  </div>
                </>
              );
            })}
          </Slider>
        </div>
      </div>
      <div>
        {/* <h1></h1> */}
        {/* <div className="flex flex-wrap items-center justify-center mt-10">
          {serviceData.map((item) => {
            return (
              <div className="flex flex-col items-center justify-center w-[250px] rounded h-[230px] shadow-xl cursor-pointer text-[#04789D] hover:bg-[#04789D] hover:text-white m-2">
                <p className="text-3xl ">{item.icon}</p>
                <h1 className="capitalize pt-5 font-semibold text-xl">
                  {item.name}
                </h1>
              </div>
            );
          })}
        </div> */}

        {/* QA Automation */}

        <div className="bg-gray pl-10 pr-10 pb-10">
          <h1 className="text-center font-bold text-3xl pt-10 text-[#18477E]">
           Diploma in Computer Applications (DCA)
          </h1>
          <div className="w-full h-[2px] bg-[#18477E] mt-3"></div>
          <div className="flex flex-col lg:flex-row items-center justify-center">
          <div className="pl-5 pt-10 lg:pt-10">
              <img src={DCA1} alt="" />
            </div>
            <div className="pt-10 w-full lg:w-1/2 md:pl-10">
              <p className="font-medium text-base">
               Computer Introduction, Types of Computer, Parts of Computer, History & Generation, Notepad, Wordpad, M.S.Paint, M.S.Word 2016, M.S.Excel 2016, M.S.PowerPoint 2016, Internet, Virus, Antivirus.
              </p>
           <h1 className="font-semibold text-lg mt-5">
                Our focus areas include
              </h1>
              <ul className="list-disc mt-2 space-y-2">
                <li className="font-semibold text-sm">
                  Computer Introduction: Computers are electronic devices that process data to perform various tasks, ranging from simple calculations to complex computations.
                </li>
                <li className="font-semibold text-sm">
                  Types of Computer: Computers can be classified into categories such as personal computers(PCs), laptops, tablets, servers, mainframes, and supercomputers, each tailored to specific computing needs.
                </li>
                <li className="font-semibold text-sm">
                 Parts of Computer: Essential computer components include the CPU(Central Processing Unit), memory(RAM), storage(hard drive or SSD), motherboard, graphics card, power supply, and peripherals like keyboard, mouse, and monitor.
                </li>
                <li className="font-semibold text-sm">
                  Journal Book & Rules of Journal Entries: The journal book records transactions chronologically, following rules of debit and credit for each entry.
                </li>
                <li className="font-semibold text-sm">
                  History & Generation: Computer history spans from early mechanical devices to modern digital computers, categorized into generations including vacuum tubes, transistors, integrated circuits, and microprocessors.
                </li>
                    <li className="font-semibold text-sm">
                 Wordpad: Wordpad is a more feature-rich text editor in Windows, offering basic formatting options for creating documents.
                </li>
                <li className="font-semibold text-sm">
                  M.S. Paint: Microsoft Paint is a basic graphics editing program included in Windows, used for creating simple drawings and editing images.
                </li>
                <li className="font-semibold text-sm">
                  M.S.PowerPoint 2016: Microsoft PowerPoint 2016 is presentation software
                  for creating slideshows with text, graphics, animations, and multimedia elements.
                </li>
              </ul>
              
            </div>
            
          </div>
        </div>

        {/* devops */}
        <div className="bg-gray pl-10 pr-10 pb-10">
          <h1 className="text-center font-bold text-3xl pt-10 text-[#18477E]">
            ADCA(Advanced Diploma in Computer Applications)
          </h1>
          <div className="w-full h-[2px] bg-[#18477E] mt-3"></div>
          <div className="flex flex-col-reverse lg:flex-row items-center justify-center">
            <div className="pt-10 w-full lg:w-1/2 ">
              <p className="font-medium text-base">
                ADCA(Advanced Diploma in Computer Applications) is an intensive program that delves deep into various aspects of computer technology and its practical applications.It extends beyond the basics of software development, database management, and web development covered in standard DCA courses
              </p>
             
              <ul className="list-disc mt-5 space-y-2">
                <li className="font-semibold text-sm">
                  The Advanced Diploma in Computer Applications(ADCA) is an intensive program designed to provide in -depth knowledge of various aspects of computer technology.
                </li>
                <li className="font-semibold text-sm">
                  Unlike standard DCA courses, ADCA extends beyond the basics of software development, database management, and web development.
                </li>
                <li className="font-semibold text-sm">
                  ADCA covers advanced topics such as artificial intelligence, machine learning, cybersecurity, and cloud computing.
                </li>
                <li className="font-semibold text-sm">
                 Students enrolled in ADCA gain practical experience through hands - on projects and real - world applications.
                </li>
                <li className="font-semibold text-sm">
                  The program aims to equip students with the skills and expertise needed to excel in today's rapidly evolving technological landscape.
                </li>
                <li className="font-semibold text-sm">
                  ADCA covers advanced topics such as artificial intelligence, machine learning, cybersecurity, and cloud computing.
                </li>
                <li className="font-semibold text-sm">
                 Students learn advanced techniques for designing and optimizing databases to manage large volumes of data effectively.
                </li>
                <li className="font-semibold text-sm">
                  ADCA covers topics related to web development, including responsive design, user experience (UX) design, and web security.
                </li>
                <li className="font-semibold text-sm">
                 The program also explores emerging technologies such as blockchain, Internet of Things (IoT), and augmented reality (AR).
                </li>
                <li className="font-semibold text-sm">
                  ADCA includes practical training in cybersecurity to identify vulnerabilities, mitigate risks, and protect against cyber threats.
                </li>
                
              </ul>
            </div>
            <div className="pl-5 pt-10 lg:pt-10">
              <img src={devops} alt="" />
            </div>
          </div>
        </div>

        {/* Virtualization */}
        <div className="bg-gray pl-10 pr-10 pb-10">
          <h1 className="text-center font-bold text-3xl pt-10 text-[#18477E]">
            TallyPrime
          </h1>
          <div className="w-full h-[2px] bg-[#18477E] mt-3"></div>
          <div className="flex flex-col lg:flex-row items-center justify-center">
            <div className="pl-5 pt-10 lg:pt-10 w-1/2">
              <img className="w-full" src={Tally1} alt="" />
            </div>
            <div className="pt-10 w-full lg:w-1/2 md:pl-10">
              <p className="font-medium text-base">
                Terminology of Accounting, Classification of Accounting, Golden Rules of Accounting, Journal Book & Rules of Journal Entries, Ledger Account, Double Entry System of Accounting, Introduction about TallyPrime, How to Start TallyPrime, Company(Creation, close, select, Alter, Backup, Delete & Restore Company), Stock Item(Creation, Alter, Delete), Sale & Purchase of Stock Item, Discount, Compnay Logo, Print Bank Details, Bill of Materials(BOM), Point of Sale(POS), Voucher Entry, Purchase Order Processing(POP), Sales Order Processing(SOP), Goods & Services Tax(GST), Batch Wise Details, Interest Calculation, Payroll.
              </p>

              <h1 className="font-semibold text-lg mt-5">
                Our focus areas include
              </h1>
              <ul className="list-disc mt-2 space-y-2">
                <li className="font-semibold text-sm">
                  Terminology of Accounting: Accounting terms include assets, liabilities, equity, revenue, expenses, depreciation, accruals, and amortization.
                </li>
                <li className="font-semibold text-sm">
                  Classification of Accounting: Accounting can be classified into financial accounting, management accounting, cost accounting, and tax accounting.
                </li>
                <li className="font-semibold text-sm">
                  Golden Rules of Accounting: The golden rules include the rules of debit and credit, which dictate how transactions are recorded in the accounting system.
                </li>
                <li className="font-semibold text-sm">
                  Journal Book & Rules of Journal Entries: The journal book records transactions chronologically, following rules of debit and credit for each entry.
                </li>
                <li className="font-semibold text-sm">
                  Ledger Account: Ledger accounts categorize transactions by account type, providing a detailed record of financial activity.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='p-10 w-5/6 bg-gray mt-10 mb-10 rounded'>
            <h1 className='font-bold text-3xl'>Application Development</h1>
            <div className='pt-5'>
                <p className='font-medium text-base'>Application development is complex and dynamic. To meet business needs, leaders must align with an application development services partner that possesses a proven track record, a quality approach to delivering consistent results and the expertise required to drive successful outcomes.</p>
                <p  className='font-medium text-base mt-5'>Our Application Development practice specializes in application services that span the full software development life cycle. Through our commitment to superior customer service and a personal dedication to helping our clients achieve success, we maintain a customer satisfaction score that is 20 percent higher than the industry average.</p>
                <p  className='font-medium text-base mt-5'>Our team follows one of two approaches to development. In full lifecycle application projects, we assume complete responsibility for analysis, design, implementation, testing and integration of systems. In cooperative development, we work with your IT professionals to jointly analyze, design, implement, test and integrate systems.</p>
                <h1 className='mt-5 font-semibold text-xl'>Transform your application development into a strategic business advantage:</h1>
                <ul className='pl-5 list-disc	mt-3'>
                    <li className='font-normal text-base'>Reduce time to market with Agile development systems.</li>
                    <li className='font-normal text-base'>Future proof strategic business processes with Service Enablement.</li>
                    <li className='font-normal text-base'>Improve development quality to reduce defects and rework.</li>
                    <li className='font-normal text-base'>Design and develop new applications in support of business process change.</li>
                    <li className='font-normal text-base'>Leverage emerging technologies and platforms to enhance capability.</li>
                    <li className='font-normal text-base'>Enable rapid development using new technologies and accelerators.</li>
                    <li className='font-normal text-base'>Work to reduce capital expenditure through utility models.</li>
                </ul>
            </div>
        </div> */}
    </div>
  );
};

export default AppDevelopment;
