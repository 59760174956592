import byte1 from "../Images/byte1.jpeg";
import byte2 from "../Images/byte2.jpeg";
import byte3 from "../Images/byte3.jpeg";
import byte4 from "../Images/byte4.jpeg";
import byte5 from "../Images/byte5.jpeg";
import byte6 from "../Images/byte6.jpeg";
import byte7 from "../Images/byte7.jpeg";
import byte8 from "../Images/byte8.jpeg";
import byte9 from "../Images/byte9.jpeg";
import byte10 from "../Images/byte10.jpeg";
import byte11 from "../Images/byte11.jpeg";

const dataObj = [
  {
    id: 1,
    imageUrl: byte1,
  },
  {
    id: 2,
    imageUrl: byte2,
  },
  {
    id: 3,
    imageUrl: byte3,
  },
  {
    id: 4,
    imageUrl: byte4,
  },
  {
    id: 5,
    imageUrl: byte5,
  },
  {
    id: 6,
    imageUrl: byte6,
  },
  {
    id: 7,
    imageUrl: byte7,
  },
  {
    id: 8,
    imageUrl: byte8,
  },
  {
    id: 9,
    imageUrl: byte9,
  },
  {
    id: 10,
    imageUrl: byte10,
  },
  {
    id: 11,
    imageUrl: byte11,
  },
];


export default dataObj