import React from "react";
import { Link } from "react-router-dom";
import AboutTopImage from "../Image/sub-header.jpg";
import Our_Approuch from "../Image/our_approch-preview.png"
import whatwedo from "../Image/whatwedo.png"
import TeamWork from "../Image/teamwork-web-concept.avif"


const About = () => {
  return (
    <div className="font-roboto pb-5">
      <div
        className="w-full h-[350px] flex items-center justify-center"
        style={{ backgroundImage: `url(${AboutTopImage})` }}
      >
        <h1 className="text-center font-bold text-5xl text-white">
        Welcome to Byte computer classes
        </h1>
      </div>
      <div className="pl-10 pr-10 w-full pt-10 flex flex-col md:flex-row items-center justify-center">
        <div className="md:w-1/2">
          <img src={TeamWork} alt="" />
        </div>
        <div className="md:w-1/2 bg-gray p-3">
          <h1 className="font-bold text-4xl pt-5">Our Mission</h1>
          <p className="font-normal text-base pt-5">
          Our mission is to cultivate a learning environment that fosters innovation, critical thinking, and problem - solving skills.We strive to equip our students with a solid foundation in computer science and technology, preparing them
          for successful careers in a rapidly evolving digital landscape.
          </p>
          <Link to="/contact">
            <button className="bg-orange pl-7 pr-7 pt-2 pb-2 text-lg rounded text-white mt-5">
              Contact
            </button>
          </Link>
        </div>
      </div>
     

      <div className="flex flex-col-reverse md:flex-row items-center justify-between pl-10 pr-10">
      
        <div className="md:w-1/2 bg-gray pt-10 pb-10 p-3">
          <h1 className="font-bold text-4xl">What We Do</h1>
          <p className="font-normal text-base pt-5">
          Programming Languages: Master the languages that power the digital world, including Python, Java, C++, and more.

          Web Development: Dive into the world of web development with courses covering HTML, CSS, JavaScript, and popular frameworks.

          Data Science and Analytics: Explore the world of data with courses in data analysis, machine learning, and artificial intelligence.

          Cybersecurity: Learn the essentials of cybersecurity and how to protect digital assets from evolving threats.

          Networking: Gain expertise in designing, implementing, and managing computer networks.
            </p>
        </div>
        <div className="md:w-1/2 flex items-center justify-center">
          <img src={whatwedo} alt="" />
        </div>
      </div>
       <div className="flex flex-col md:flex-row items-center justify-between pl-10 pr-10">
       <div className="md:w-1/2 flex items-center justify-center">
          <img src={Our_Approuch} alt="" />
        </div>
        <div className="md:w-1/2 bg-gray pt-10 pb-10 p-3">
          <h1 className="font-bold text-4xl">Join Us on the Journey of Learning</h1>
          <p className="font-normal text-base pt-5">
          Embark on a transformative learning journey with Byte Computer Classes.Whether you 're a beginner looking to build a strong foundation or a professional aiming to upskill, we have the programs and resources to help you achieve your goals. Join us and become a part of a community that embraces knowledge, innovation, and success in the world of technology.

           Byte Computer Classes - Igniting Minds, Empowering Futures.
          </p>
        </div>
        
      </div>
    </div>
  );
};

export default About;
