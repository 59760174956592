import qa_automation from "../Image/QA-Automation.jpg"
import virtualization from "../Image/Virtualization-01.jpg"
import devops from "../Image/devopsBg-1.jpg"


const cartData = [
    {
        id:1,
        img: qa_automation,
        name: "Diploma in Computer Applications.",
        para: "Bridging the Gap Between Theory and Practice, Equipping Individuals with Essential Skills for Success in the Digital Era. Empowering Students with Comprehensive Knowledge and Practical Expertise to Thrive in Diverse Fields of Information Technology and Computer Science, Paving the Way for Lucrative Career Opportunities and Continued Professional Growth. "
    },
    {
        id: 2,
        img: virtualization,
        name: "TallyPrime",
        para: "Terminology of Accounting, Classification of Accounting, Golden Rules of Accounting, Journal Book & Rules of Journal Entries, Ledger Account, Double Entry System of Accounting, Introduction about TallyPrime, How to Start TallyPrime, Company (Creation, close, select, Alter, Backup, Delete & Restore Company), Stock Item (Creation, Alter, Delete), Sale & Purchase of Stock Item, Discount, Compnay Logo, Print Bank Details, Bill of Materials(BOM), Point of Sale(POS), Voucher Entry, Purchase Order Processing(POP), Sales Order Processing(SOP), Goods & Services Tax(GST), Batch Wise Details, Interest Calculation, Payroll."
    },
    {
        id: 3,
        img: devops,
        name: "ADCA (Advanced Diploma in Computer Applications)",
        para: "ADCA(Advanced Diploma in Computer Applications) is an intensive program that delves deep into various aspects of computer technology and its practical applications.It extends beyond the basics of software development, database management, and web development covered in standard DCA courses"
    },
    
]

export default cartData